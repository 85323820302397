<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('财务组') == -1">
      <cw403></cw403>
    </div>
    <div v-else>
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            Loongcheer周报数据-已添加三方支付数据
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          ></date-picker>
          <el-select
            v-model="selectedProjects"
            @change="selectProjects"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputProject"
                @input="filterProjects" 
                clearable
              ></el-input>
              <button @click="selectAllProjects" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="clearProjects">清空</button>
              <el-option v-for="item in filteredProjectList" :key="item" :label="item" :value="item"></el-option>
            </div>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch" :loading="loading">搜索</el-button>
          <el-button v-if="showClearButton" type="primary" plain icon="el-icon-search" @click="clearFilters">清空筛选</el-button>
          <el-button type="primary" plain icon="el-icon-download" @click="exportToExcel" style="float: right;">导出结果</el-button>
        </div>
        <!-- 其他内容 -->
        <!-- 新增的搜索结果展示table -->
        <el-table
          v-if="searchResults.length > 0"
          :data="searchResults"
          style="width: 100%"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column"
            :prop="column"
            :label="column"
          ></el-table-column>
        </el-table>
      </div>
      <el-dialog title="新筛选页面" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <!-- 对话框内容 -->
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import cw403 from './403.vue';
import { fetchData } from '../../api/index';
import * as XLSX from 'xlsx'; // 引入xlsx库

export default {
  name: 'new-filter-page',
  components: {
    cw403,
  },
  data() {
    return {
      user_group: localStorage.getItem('user-group'),
      date: [],
      selectedProjects: [],
      inputProject: '',
      projectList: [],
      filteredProjectList: [], // 新增的过滤后的项目列表
      dialogVisible: false,
      loading: false,
      showClearButton: false,
      searchResults: [], // 新增的搜索结果
      tableColumns: [], // 新增的表格列
    };
  },
  methods: {
    selectProjects(val) {
      this.showClearButton = val.length > 0;
    },
    filterProjects() {
      // this.filteredProjectList = [];
      try {
        this.filteredProjectList = this.projectList.filter(item => 
          item.toLowerCase().includes(this.inputProject.toLowerCase())
        );
        if (this.filteredProjectList.length == 0) {
          this.filteredProjectList = ["空"];
        }
          
      } catch (error) {
        console.error("Error filtering projects:", error);
      }
    },
    selectAllProjects() {
      this.selectedProjects = this.projectList;
    },
    clearProjects() {
      this.selectedProjects = [];
    },
    handleSearch() {
      this.loading = true;
      const a = this.date;
      const b = this.selectedProjects.length ? this.selectedProjects : ['HeroSummoner'];

      this.$axios
        .post(
          'daily/report/',
          {
            project: this.selectedProjects,
            date_range: this.date,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 200) {
            this.searchResults = res.data.list;
            this.tableColumns = Object.keys(res.data.list[0] || {});
            this.loading = false;
          } else {
            this.handleSearchError();
          }
        })
        .catch(this.handleSearchError);
    },
    exportToExcel() {
      if (this.searchResults.length === 0) {
        this.$message.warning('没有搜索结果可以导出');
        return;
      }
      const ws = XLSX.utils.json_to_sheet(this.searchResults);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '搜索结果');

      // 设置列宽
      const colWidths = this.tableColumns.map(() => ({ wpx: 100 }));
      ws['!cols'] = colWidths;

      // 获取当前日期
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

      // 导出文件名带上日期
      XLSX.writeFile(wb, `搜索结果_${formattedDate}.xlsx`);
    },
    handleSearchError() {
      this.loading = false;
      this.$message.error('搜索失败，请稍后重试');
    },
    clearFilters() {
      this.date = [];
      this.selectedProjects = [];
      this.showClearButton = false;
    },
    getProjects() {
      this.$axios
        .get('daily/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.projectList = res.data.project_data;
            this.filteredProjectList = this.projectList; // 初始化过滤后的项目列表
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.getProjects();
  },
};
</script>

<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.elx-header--column.col--ellipsis > .elx-cell .elx-cell--title {
  overflow: initial;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.elx-table {
  font-size: 12px;
}
.elx-table .elx-header--column {
  position: relative;
  line-height: 1;
  text-align: left;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>

<style scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}
</style>
