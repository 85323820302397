<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('市场组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('市场组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            项目模块-已添加三方收入
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">

        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">分组维度</font>
          <el-checkbox-group @change="demo" v-model="checkList" style="display: inline">
            <el-checkbox label="日期" ></el-checkbox>
            <el-checkbox label="项目" disabled></el-checkbox>
            <el-checkbox label="平台"></el-checkbox>
            <el-checkbox label="国家"></el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group @change="date_all_change" v-model="date_all_checked" style="display: inline;margin-left:20px;">
            <el-checkbox label="日期是否汇总"></el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          ></date-picker>
          <el-select v-model="gameType" @change="selectGT"  placeholder="项目类型" style="margin-right: 10px; width: 120px">
            <el-option v-for="item in game_types" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>

          <el-select
            v-model="QQ.gameName"
            @change="selectXM"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputXM"
                @keyup.enter.native="enterXM"
                clearable
              ></el-input>
              <button @click="selectAll1" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu1">清空</button>
              <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="list1 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.gameName" :key="item">
                <el-tag closable @close="handleClose(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select v-model="QQ.phone" @change="selectPT" multiple placeholder="平台" style="margin-right: 10px; width: 120px">
            <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select
            v-model="QQ.country"
            @change="selectGZ"
            multiple
            placeholder="所有国家/地区"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputGZ"
                @keyup.enter.native="enterGZ"
                clearable
              ></el-input>
              <button @click="selectAll3" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu3">清空</button>
              <el-option v-for="item in list3" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list3 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.country" :key="item">
                <el-tag closable @close="handleCloseGZ(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch" style="margin-bottom: 10px; margin-top: 10px"
            >搜索</el-button
          >
          <el-button v-if="fanhui" type="primary" plain icon="el-icon-search" @click="fanhuiss">返回全部</el-button>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>

          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>

        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
          height="550"
          border
          class="table"
          ref="multipleTable"
          :header-cell-style="{ color: '#696969' }"
        >
          <af-table-column
            :min-width="120"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <af-table-column
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>

        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination>

        <!-- <p style="margin-bottom: 20px; margin-top: -10px; font-size: 16px; color: gray">项目ROI</p>
        <div id="chart1" style="width: 100%; height: 400px; background: #fff"></div> -->
      </div>

      <el-dialog title="项目模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>筛选条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>项目</li>
            <li>渠道</li>
            <li>国家</li>
            <li>日期范围</li>
            <li>事件名称</li>
            <li>媒体渠道</li>
          </ul>
          <h2 style="margin-top: 20px">数据指标</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>基本信息</li>
            <li>事件第0天触发数</li>
            <li>事件第0天的人均触发</li>
            <li>事件第1天触发数</li>
            <li>...</li>
            <li>事件第N天第触发数</li>
            <li>事件第N天的人均触发</li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>
              此处信息抓取依赖AF的登陆Cookie，且每日账户请求数受限，故只抓取部分变现/内购数据，如有其他需要观察的事件信息，请联系开发同事
            </li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { fetchData } from '../../api/index';
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn) 
import json2csv from 'json2csv';
import echarts from 'echarts';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'product-table7',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      gameType: 'all',
      inputXM: '',
      inputGZ: '',
      user_group: localStorage.getItem('user-group'),
      time: [],
      checkList: ['项目'],
      gameOne: [],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      pictLoading: true,
      pageInfo: {
        size: 200,
        current: 1,
        total: 0,
      },
      game_types: [
        { label: '所有', value: 'all' },
        { label: '网游', value: 't0' },
        { label: '独立游戏', value: 't1' },
        { label: '中轻度', value: 't2' }
      ],
      date: [],
      date_all: 'false',
      date_all_checked: false,
      dialogVisible: false,
      type: false,
      list1: [],
      list2: [
        { label: '所有平台', value: 'all' },
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      list3: [],
      zhexianData: [],
      QQ: {
        date: [],
        gameName: [],
        phone: [],
        country: []
      },
      tableSearchList: [],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    this.getTable();
    this.getXM();
    this.getGZ();
    this.PageCount();
  },
  methods: {
    //回车选中第一项方法
    enterXM() {
      if (this.QQ.gameName.includes(this.list1[0])) {
        console.log('已选中');
      } else this.QQ.gameName.push(this.list1[0]);
      this.getGZ();
    },
    enterGZ() {
      if (this.QQ.country.includes(this.list3[0])) {
        console.log('已选中');
      } else this.QQ.country.push(this.list3[0]);
    },
    handleClose(tag) {
      this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    handleCloseGZ(tag) {
      this.QQ.country.splice(this.QQ.country.indexOf(tag), 1);
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      this.pictLoading = true;
      this.QQ.gameName = '';
      this.QQ.phone = '';
      this.QQ.country = '';
      this.getTable();
      this.fanhui = false;
    },
    //添加全选功能
    selectAll1() {
      this.QQ.gameName = this.list1;
      this.getGZ();
    },
    qinchu1() {
      this.QQ.gameName = [];
      this.checked1 = false;
    },
    // 日期是否汇总
    date_all_change(val){
      console.log(val);
      if(val===true){
        this.date_all = 'true'
      }
      else{
        this.date_all = 'false'
      }
    },
    selectAll2() {
      this.QQ.phone = [];
      if (this.checked2) {
        this.QQ.phone = ['all', 'ios', 'android'];
      } else this.QQ.phone = [];
    },
    selectAll3() {
      this.QQ.country = this.list3;
    },
    qinchu3() {
      this.QQ.country = [];
      this.checked3 = false;
    },
    getTable() {
      //Vue.http.options.emulateJSON = true;

      this.$http
        .get('item/init_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.tableData = res.data.list;
            // this.pageInfo.total = res.data.list.length;
            // this.tableColumns = [];
            // for (var pro in res.data.list[0]) {
            //   this.tableColumns.push(pro);
            // }
            // this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            // this.tableColumns2 = this.tableColumns;
            this.pictLoading = false;
            // this.$refs.searchInput.searchTable();
            // this.getZX();
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    // 触发搜索按钮
    handleSearch() {
      this.pictLoading = true;
      this.fanhui = true;
      var a = this.date;
      var b = [];
      var c = [];
      var d = [];
      if (this.QQ.gameName == '') b = ['all'];
      else b = this.QQ.gameName;
      if (this.QQ.phone == '') c = ['all'];
      else c = this.QQ.phone;
      if (this.QQ.country == '') {
        d = ['all'];
      } else d = this.QQ.country;
      //post请求获取搜索内容
      this.$axios
        .post(
          'item/info/',
          {
            project: b,
            platform: c,
            date_range: a,
            country: d,
            game_type: this.gameType,
            check_list:this.checkList,
            date_all:this.date_all
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.tableData = res.data.list;
          this.tableColumns = [];
          for (var pro in res.data.list[0]) {
            this.tableColumns.push(pro);
          }
          this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
          this.tableColumns2 = this.tableColumns;
          this.pictLoading = false;
          this.pageInfo.total = res.data.list.length;
          this.$refs.searchInput.searchTable();
        })
        .catch((e) => {});
    },
    handleSizeChange() {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectXM(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else this.checked1 = false;
      if (this.QQ.gameName != '') {
        this.$axios
          .post(
            'basicdata/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '') {
        this.$axios
          .post(
            'basicdata/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
    },
    selectPT(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list2) {
        this.checked2 = true;
      } else this.checked2 = false;
      if (this.QQ.phone != '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'item/country_data/',
            {
              project: this.QQ.gameName,
              platform: this.QQ.phone
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'item/country_data/',
            {
              project: this.list1,
              platform: this.QQ.phone
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName != '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'item/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'item/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
            this.list3.unshift('all');
          })
          .catch((e) => {});
      }
    },
    selectGT(val) {
      this.QQ.gameName = [];
      this.checked1 = false;
      this.$http
        .get('item/project_data/', {
          params:  {game_type: val},
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    selectGZ(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list3) {
        this.checked3 = true;
      } else this.checked3 = false;
    },
    //获取游戏名
    getXM() {
      this.$http
        .get('item/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    //获取所有国家信息
    getGZ() {
      //Vue.http.options.emulateJSON = true;

      this.$http
        .get('basicdata/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');

            this.$axios
              .post(
                'item/country_data/',
                {
                  project: this.list1,
                  platform: ['all', 'ios', 'android']
                },
                {
                  headers: {
                    Authorization: 'JWT ' + localStorage.getItem('jwt-token')
                  }
                }
              )
              .then((res) => {
                this.list3 = res.data.country_data;
                this.list3.unshift('all');
              })
              .catch((e) => {});
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"项目模块"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      }
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];

    setTimeout(() => {
      this.teacherChart1 = echarts.init(document.getElementById('chart1'));
    }, 500);
  },
  watch: {
    time() {
      var char = this.gameOne;
      var series = [];
      for (var i = 0; i < char.length; i++) {
        series.push({
          type: 'line',
          showSymbol: false
        });
      }
    },
    inputXM() {
      var newlist1 = [];
      this.list1.filter((item) => {
        if (item.toUpperCase().includes(this.inputXM.toUpperCase())) {
          newlist1.push(item);
        }
      });
      this.list1 = newlist1;
      if (this.inputXM == '') {
        this.getXM();
      }
    },
    inputGZ() {
      var newlist3 = [];
      this.list3.filter((item) => {
        if (item.toUpperCase().includes(this.inputGZ.toUpperCase())) {
          newlist3.push(item);
        }
      });
      this.list3 = newlist3;
      if (this.inputGZ == '') {
        this.getGZ();
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>